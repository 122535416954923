<template lang="pug">
div
    template( v-if="loaded")
      language(@submit="onCreate")
        template(#header)
          | {{ translate('admin.translations.new.title' ) }}
        template(#field)
          .field.clearfix
            helpers-label.label(field="translation.language")
            select-autocomplete(v-model="language" :collection="filtered_languages")
            helpers-validation-message.error(:errors="validation" field="language")
        template(#left)
          .button.secondary.inverted.short( @click="onCancel")
            | {{translate('admin.buttons.cancel')}}
        template(#right)
          button.button.primary.short( type="submit" @click="onCreate" v-if="$can('translations__create')" )
            | {{ translate('admin.translations.new.add_language' ) }}
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '../../../mixins/root_computed.js';
import mixin_language from './language.js';
import language from '../language.vue';
export default{
  mixins: [Root,mixin_language],
  components: {language},
  validations: {
    "language": "required"
  },

  data() {
    return {
      loaded: false,
      language: null,
      languages: [],
      published: false,
      primary: false
    };
  },

  computed: {
    filtered_languages() {
      return this.languages.filter(language => !this.current_shop.possible_languages.includes(language.code));
    }
  },

  methods: {
    data_synced() { return this.loaded = true; },

    onCancel() { return this.closeModal(); },

    onCreate(e) {
      e.preventDefault();
      if (!this.$validate()) { return; }
      return this.performAction();
    }
  }
}
</script>