import URI from '../libs/uri/uri.js';
import moment from 'moment';
import SVGInjector from 'svg-injector'; // TODO: Review and replace if needed to remove app/views/layouts/admin.html.erb:29 based solution
import '../models/cache.js';
import { useUser, username } from "~/admin/components/stores/user";

const image_url = (image, type = "admin_tiny") => {
  if (image.id) { // we are not supposed to show image previews before upload is complete any more
    return image.sizes?.[type] || image.url;
  }
}

export {
  image_url
}

export default {
  computed: {
//    app: -> this.$root,
    clean_possible_languages() {
      let idx;
      const clean_langs = this.current_shop?.possible_languages.slice(0) || [];
      if ((idx = clean_langs.indexOf(this.current_shop?.default_language)) > -1) {
        clean_langs.splice(idx, 1);
      }
      return clean_langs;
    },

    all_possible_languages() {
      return this.clean_possible_languages.concat([this.current_shop?.default_language]);
    }
  },

  methods: {
    $set(obj, key, value){
      obj[key] = value
      obj[key]
    },
    $set1(path, value) {
      let schema = this;  // a moving reference to internal objects within obj
      let pList = path.split('.');
      let len = pList.length;
      for(let i = 0; i < len-1; i++) {
        const elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
      }

      schema[pList[len-1]] = value;
    },

    $get1(full_key) {
      const keys = full_key.split(".");
      const last = keys.pop();
      let object = this;
      for (var key of keys) {
        var match_result;
        if (match_result = key.match(/^([^\[]*)\[([^\]]*)\]$/)) {
          object = object?.[match_result[1]];
          object = object?.[match_result[2]];
        } else {
          object = object?.[key];
        }
      }
      return object?.[last];
    },

    inJson(promise) {
      // Start using #2, returns validation errors directly in catch
      return new Promise((resolve, reject) => {
        promise.then(response => {
          const parse = response.json();
          return parse.then(json => {
            this.attach_pagination(response, json);
            return resolve(json);
          });
        });
        return promise.catch(reject);
      });
    },


    api_request(method, url, params = {}, options = {}) {
      let request_language;
      if (options.headers == null) { options.headers = {}; }
      if (!("Accept" in options.headers)) { options.headers["Accept"] = "application/json"; }
      if (!("Content-Type" in options.headers)) { options.headers["Content-Type"] = "application/json"; }
      if (!("Accept-Version" in options.headers)) { options.headers["Accept-Version"] = "v1"; }
      const domain = this.current_shop?.domain;
      if (!("Current-Shop" in options.headers)) { if (domain) { options.headers["Current-Shop"] = domain; } }
      if (!("Current-Shop" in options.headers)) { if (window.localStorage.shop_domain) { options.headers["Current-Shop"] = window.localStorage.shop_domain; } }
      if ((!("Accept-Language" in options.headers)) && (request_language = this.locale)) { // <-- why here is not I18n.locale?
        options.headers["Accept-Language"] = request_language;
      }

      url = new URI(`/api/v1/${url}`);
      url.normalizePath();
      return this.request(method, url, params, options, response => {
        // TODO: language should be taken only from user, not on each request. On login we can take shop default lang (does it work this way?)
        let response_asset_version, response_languge, response_languges;
        if (response_languge = (response.headers.get("X-Content-Language") || response.headers.get("x-content-language"))) {
          this.locale = response_languge;
          moment.locale(window.I18n.locale);
        }

        if (response_languges = (response.headers.get("X-Content-Languages") || response.headers.get("x-content-languages"))) {
          this.app.possible_admin_locales = response_languges.split(",");
        }

        if (response_asset_version = (response.headers.get("X-Assets-Version") || response.headers.get("x-assets-version"))) {
          if (this.old_assets_version && (this.old_assets_version !== response_asset_version)) { this.got_new_assets = true; }
          return this.old_assets_version = response_asset_version;
        }
      });
    },

    request(method, url, params = {}, options = {}, cb) {
      url = new URI(url);
      switch (method) {
        case "GET": case "HEAD":
          url.addQuery(params);
          break;
        default:
          options.body = JSON.stringify(params, function(k, v) { if (v === undefined) { return null; } else { return v; } });
      }

      options.method = method;
      return this.raw_request(url, options, cb);
    },

    // just keeping here optins without modifying them
    raw_request(url, options = {}, cb) {
      let request_language;
      const request_id   = this.app.request_sequence;
      const requests_now = this.app.current_requets || [];
      requests_now.push(request_id);
      this.app.current_requets  = requests_now;
      this.app.request_sequence = request_id + 1;

      // add headers
      if (options.headers == null) { options.headers = {}; }
      //options.headers["Accept"] = "application/json" unless "Accept" of options.headers
      //options.headers["Content-Type"] = "application/json" unless "Content-Type" of options.headers
      if (!("Accept-Version" in options.headers)) { options.headers["Accept-Version"] = "v1"; }
      const domain = this.app.current_shop?.domain;
      if (!("Current-Shop" in options.headers)) { if (domain) { options.headers["Current-Shop"] = domain; } }
      if (!("Current-Shop" in options.headers)) { if (window.localStorage.shop_domain) { options.headers["Current-Shop"] = window.localStorage.shop_domain; } }
      if ((!("Accept-Language" in options.headers)) && (request_language = this.locale)) { // <-- why here is not I18n.locale?
        options.headers["Accept-Language"] = request_language;
      }
      // add headers

      const promise = fetch(url, options);

      promise.catch(error => {
        if (this.app.current_requets) { this.app.current_requets.splice(this.app.current_requets.indexOf(request_id), 1); }
        return Promise.reject(error);
      });

      return promise.then(response => {
        if (this.app.current_requets) { this.app.current_requets.splice(this.app.current_requets.indexOf(request_id), 1); }
        cb?.(response);
        // TODO: move to api_request, this here is meant for also requests outside our API
        setTimeout((() => { return this.flash= null; }), 4000);
        if ((response.status >= 100) && (response.status < 200)) {
          return Promise.pending(response);
        } else if ((response.status >= 200) && (response.status < 300)) {
          // TODO: move to api_request, this here is meant for also requests outside our API
          if ((options.method === "POST") || (options.method === "PATCH") || (options.method === "DELETE")) {
            this.flash= null;
            if ((url.toString().indexOf("login") === -1) && (url.toString().indexOf("skip_flash=true") === -1) && (url.toString().indexOf("search") === -1)) {
              this.$nextTick(() => { return this.flash="admin.flash.success"; });
            }
          }
          return Promise.resolve(response);
        } else if ((response.status >= 300) && (response.status < 400)) {
          return Promise.pending(response);
        } else if ((response.status >= 400) && (response.status < 500)) {
          return Promise.reject(response);
        } else if ((response.status >= 500) && (response.status < 600)) {
          return Promise.reject(response);
        }
      });
    },
    username(user) { return username(user) },
    update_3rdparty_js(user = this.current_user){
      clearTimeout(this.crispTimeout);
      return this.crispTimeout = setTimeout(() => {
        if (process.env.VITE_CRISP_ID) {
          if (user?.id) {
            const data = {
              email: user.email,
              user_id: user.id,
              name: this.username(user),
              language: user.locale
            };
            if (!user?.admin) {
              data.company = {
                id: this.current_shop?.id,
                name: this.current_shop?.name,
                plan: this.current_shop?.subscription?.plan_name,
                monthly_spend: this.current_shop?.subscription?.price,
                website: this.current_shop?.external_hostname
              };
            }

            $crisp.push(["do", "chat:hide"]);
            $crisp.push(["safe", true]);
            if (user?.id) {
              $crisp.push(["set", "user:email", user.email]);
              $crisp.push(["set", "user:nickname", [this.username(user)]]);
            }
            if (!user?.admin) {
              $crisp.push(["set", "user:company", [
                this.current_shop?.name,
                {
                  id: this.current_shop?.id,
                  url: this.current_shop?.external_hostname,
                  plan: this.current_shop?.subscription?.plan_name,
                  monthly_spend: this.current_shop?.subscription?.price,
                }
              ]]);
              $crisp.push(["set", "session:data", [[
                ["user_id", user.id],
                ["language", user.locale],
                ["shop_id", this.current_shop?.id],
                ["name", this.username(user)],
                ["plan", this.current_shop?.subscription?.plan_name],
                ["monthly_spend", this.current_shop?.subscription?.price],
                ["url", this.current_shop?.external_hostname],
              ]]]);
            }
          }

          if (process.env.SENTRY_ADMIN_JS) {
            window.Sentry?.configureScope(scope => {
              if (this.current_shop) {
                data.domain = this.current_shop.domain;
              }
              return scope.setUser(data);
            });
          }

          if (process.env.FULLSTORY_ALLOW) {
            window.FS?.identify(user.id, {
              displayName: this.username(user),
              email: user.email,
              reviewsWritten_int: 14
            });
          }
        }
      }
      , 100);
    },

    if_authenticated() {
      return new Promise((resolve, reject) => {
        return this.ifCurrentUser(true).then(res=> {
          return this.ifCurrentShop(true).then(() => resolve()).catch(() => {
            const params = {};
            if (window.localStorage.shop_id) {
              if (!params.where) { params.where = `id:${window.localStorage.shop_id}`; }
            }
            if (window.localStorage.shop_domain) {
              if (!params.where) { params.where = `domain:${window.localStorage.shop_id}`; }
            }
            return this.detectShop(params).then(() => resolve()).catch(() => this.redirectToBackUrl("stores"));
          });
        }).catch(err=> {
          console.log(err);
          return this.redirectToBackUrl("authenticate");
      });
      });
    },

    async ifCurrentUser(custom = false, acl = true) {
      if(acl) {
        try{
          await this.$userStore.loadAcl()
        }catch (e) {
          console.log("no ability to load ACL", e)
        }
      }
      const promise = new Promise((resolve, reject) => {
        if (this.current_user === null) {
          return this.axios.get("user").then(resp => {
            this.current_user = resp.data;
            this.$userStore.user = this.current_user
            return resolve();
          }).catch(() => {
            this.current_user = false;
            return reject();
          });
        } else {
          if (this.current_user) {
            this.$userStore.user = this.current_user
            return resolve();
          } else { return reject(); }
        }
      });
      if (!custom) { promise.catch(() => this.redirectToBackUrl("authenticate")); }
      return promise;
    },

    ifCurrentShop(custom = false) {
      const promise = new Promise((resolve, reject) => {
        // uses to change current domain based on url param
        // it's very needed for oauth apps, like RTE
        if (this.$route.query.domain && this.current_shop && (this.$route.query.domain !== this.current_shop.domain)) {
          this.current_shop = null;
          window.localStorage.shop_domain = this.$route.query.domain;
        }

        if (this.current_shop === null) {
          return this.axios.get("shop").then(resp=> {
            this.current_shop = resp.data;
            return resolve();
          }).catch(() => { this.current_shop = false; return reject(); });
        } else {
          if (this.current_shop) { return this.fetch_cached_collection("currencies").then(() => resolve()); } else { return reject(); }
        }
      });
      if (!custom) { promise.catch(() => this.redirectToBackUrl("stores")); }
      return promise;
    },

    build_url(params) {
      const uri = new URI(this.$route.path);
      uri.addQuery(params);
      return uri;
    },

    attribute_translation_key(name) {
      return `translations.${this.data_language}.${name}`;
    },

    translated_key(model, name) {
      return `${model}.${this.attribute_translation_key(name)}`;
    },

    attr_by_key(obj, key) {
      let translation = obj;
      const arr = key.split(".");
      for (var el of arr) {
        if (el === '') { continue; }
        translation = translation[el];
        if (translation === null) { return obj[arr[-1]]; }
      }
      return translation;
    },

    attach_pagination(response, json) {
      let pagination;
      if ((pagination = (response.headers.get("X-Pagination") || response.headers.get("x-pagination"))) && (json instanceof Array)) {
        return json.pagination = JSON.parse(pagination);
      }
    },

    fetch_collection(name, params = {}, force = false) {
      return new Promise((resolve, reject) => {
        let was;
        if ((was = this.app[name]) && was.pagination && (was.pagination.page === params.page) && !force) {
          this[name] = was;
          return resolve(was);
        } else {
          const request = this.inJson(this.api_request("GET", `/${name}`, params));
          request.then(collection => {
            name = name.replace(/\//g, "_"); // vue wants dash
            this[name] = collection;
            return resolve(this[name]);
          });
          return request.catch(reject);
        }
      });
    },
    fetch_collection_all(path, params = {}) {
      const name = path.replace(/\//g, "_"); // vue wants dash
      params.page = 1;
      let all_data = [];
      return this.fetch_collection(path, JSON.parse(JSON.stringify(params || {}))).then(data=> {
        all_data = all_data.concat(data);
        const pages = Math.ceil(data.pagination.total / data.pagination.per);
        const requests = [];

        return new Promise((resolve, reject) => {
          if (pages > 1) {
            for (let page = 2, end = pages, asc = 2 <= end; asc ? page <= end : page >= end; asc ? page++ : page--) {
              params.page = page;
              requests.push(this.fetch_collection(path, (params || {})));
            }
          }

          return Promise.all(requests).then(paged_data => {
            for (var paged_page of paged_data) {
              all_data = all_data.concat(paged_page);
            }
            this[name] = all_data;
            return resolve(all_data);
          });
        });
      });
    },

    fetch_cached_collection(path, params = {}) {
      const name = path.replace(/\//g, "_"); // vue wants dash
      const key = JSON.parse(JSON.stringify(params || {})); // object.clone
      key.name = name;
      return new Promise((resolve, reject) => {
        let was;
        if (was = Cache.get(key)) {
          this[name] = was;
          return resolve(was);
        } else {
          const request = this.inJson(this.api_request("GET", `/${path}`, params));
          request.then(collection => {
            const value = collection;
            if (!params.no_assign) { this[name] = collection; }
            Cache.set(key, value);
            return resolve(value);
          });
          return request.catch(reject);
        }
      });
    },

    fetch_cached_collection_all(path, params = {}) {
      const name = path.replace(/\//g, "_"); // vue wants dash
      params.page = 1;
      let all_data = [];
      return this.fetch_cached_collection(path, JSON.parse(JSON.stringify(params || {}))).then(data=> {
        all_data = all_data.concat(data);
        const pages = Math.ceil(data.pagination.total / data.pagination.per);
        const requests = [];

        return new Promise((resolve, reject) => {
          if (pages > 1) {
            for (let page = 2, end = pages, asc = 2 <= end; asc ? page <= end : page >= end; asc ? page++ : page--) {
              params.page = page;
              requests.push(this.fetch_cached_collection(path, (params || {})));
            }
          }

          return Promise.all(requests).then(paged_data => {
            for (var paged_page of paged_data) {
              all_data = all_data.concat(paged_page);
            }
            if (!params.no_assign) { this[name] = all_data; }
            return resolve(all_data);
          });
        });
      });
    },


    assign_file(e, key, cb) {
      return this.each((e.target.files || [e.target]), (input, _, index) => {
        return window.read_file(input, file => {
          this.$set1(key, file.url);
          return cb?.();
        }
        , null,
          {reader: 'readAsText'});
      });
    },

    filter(array, fn) {
      return this.each(array, function(item, results, index) { if (fn(item, results, index)) { return results.push(item); } });
    },

    map(array, fn) {
      return this.each(array, (item, results, index) => results.push(fn(item, results, index)));
    },

    concat(array) {
      let results = [];
      for (let index = 0; index < array.length; index++) {
        var item = array[index];
        results = results.concat(item);
      }
      return results;
    },

    each(array, fn) {
      const results = [];
      for (let index = 0; index < array.length; index++) {
        var item = array[index];
        fn(item, results, index);
      }
      return results;
    },

    set_validation(component, klass, response, rewrite, cb){
      return response.json().then(js=> {
        if (rewrite !== false) { component[klass] = js; }
        //          console.log(js) # hack to make it working
        if (js.errors) {
          let errs, type;
          for (type in js.errors.data) {
            errs = js.errors.data[type];
            component.$set1(`validation.${klass}.${type}.invalid`, true);
            for (var err of errs) { component.$set1(`validation.${klass}.${type}.${err}`, true); }
          }
          for (type in js.errors.messages) {
            errs = js.errors.messages[type];
            component.$set1(`validation.${klass}.${type}.invalid`, true);
            component.$set1(`validation.${klass}.error_messages.${type}`, errs);
          }
//          for type, errs of js.errors.details
//            component.$set1("validation.#{klass}.#{type}.invalid", true)
//            component.$set1("validation.#{klass}.error_messages.#{type}", errs)
          // scroll to the first error place
          component.$nextTick(() => { let el;
          if (el = document.querySelector(".formError")) { return el.scrollIntoView; } });
        }
        return cb?.();
      });
    },



    clean_all_validations(component) {
      return (() => {
        const result = [];
        for (var type in (component.validation || {})) {
          result.push(component.$set1(`validation.${type}`, {}));
        }
        return result;
      })();
    },

    scrollTo(element, to, duration) {
      if (duration <= 0) { return; }

      const difference = to - (element.getBoundingClientRect().y);
      const perTick = (difference / duration) * 10;
      setTimeout((function() {
        window.scroll(0, element.getBoundingClientRect().y + perTick);
        if (element.getBoundingClientRect().y >= to) { return; }
        scrollTo(element, to, duration - 10);
      }), 10);
    },

    getClosest(el, tag) {
// this is necessary since nodeName is always in upper case
      tag = tag.toUpperCase();
      while (true) {
        if (el.nodeName === tag) {
// tag name is found! let's return it. :)
          return el;
        }
        if (!(el = el.parentNode)) {
          break;
        }
      }

      // not found :(
      return null;
    },

    onlyOpenDropDownMenu(e) {
      e.stopPropagation();

      let {
        target
      } = e;
      if ((target.nodeName === 'INPUT') || (target.nodeName === 'TEXTAREA')) { target = target.parentNode; }

      const open = target.classList.contains('open');
      if (!open) { target.classList.add('open'); }
    },

    openDropDownMenu(e) {
      e.stopPropagation();

      let {
        target
      } = e;
      if ((target.nodeName === 'INPUT') || (target.nodeName === 'TEXTAREA')) { target = target.parentNode; }
      if (target.nodeName === 'LABEL') { target = target.nextSibling; }

      const open = target.classList.contains('open');

      for (var select of document.querySelectorAll('.fancy-select')) {
        if (select === target) { continue; }
        select.classList.remove('open');
      }

      if (!open) { target.classList.add('open'); }

      // v1 compatibility
      if (document.querySelector(".template-container.admin-v1")) {
        for (var selects of document.querySelectorAll('.fancy-select ul')) { selects.classList.remove('open'); }
        if (e.target.parentNode != null) { e.target.parentNode.querySelector('ul')?.classList.add('open'); }
      }
    },

    doRedirect(fallback = "/") {
      let target = this.$route.query.back || fallback;
      if (target.startsWith('/authenticate')) target = fallback
      return this.navigate(URI.decode(target));
    },

    detectShop(params = {}) {
      return new Promise((resolve, reject) => {
        return this.fetch_cached_collection("shops", params).then(shops => {
          // exiting if have some problems
          if (params.where && (shops.length === 0)) {
            return reject(shops);
          }

          if (params.where || (shops.length === 1)) {
            window.localStorage.shop_id = shops[0].id;
            window.localStorage.shop_domain = shops[0].domain;
            this.current_shop = shops[0];
            this.data_language = this.current_shop.default_language;
            return this.fetch_cached_collection("currencies").then(() => resolve(this.current_shop));
          } else { return reject(shops); }
        });
      });
    },


    closeAllDropDowns(e) {
      let node;
      for (node of document.querySelectorAll('.fancy-select')) {
        if (node.querySelector('input,textarea') === e.target) { continue; }
        node.classList.remove('open');
        if (node.parentElement.querySelector('.fake-input')) { node.parentElement.querySelector('.fake-input').classList.remove('focus'); }
      }

      // v1 compatibility
      if (document.querySelector(".template-container.admin-v1")) {
        return (() => {
          const result = [];
          for (node of document.querySelectorAll('.fancy-select')) {
            if (node.querySelector('ul')) { result.push(node.querySelector('ul').classList.remove('open', 'focus')); } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      }
    },

    rowLink(e, el) {
      let href;
      if (e.target.classList.contains("row-link-blocker") || e.target.closest(".row-link-blocker")) { return; }
      if (typeof el === 'string') {
        href = this.getClosest(e.target, el).getAttribute('href');
      } else {
        href = e.target.getAttribute('href');
        if (!href) { href = e.target.parentNode.getAttribute('href'); }
      }

      if (href) { return this.navigate(href); }
    },

    loadSvgSprites() {
      const svgsToInline = document.querySelectorAll('img.svg-sprite');
      return svgsToInline.forEach((svg) =>
        svg.addEventListener('load', (e => SVGInjector(e.target)), true));
    },

    price(num, obj, preferSymbol = true) {
      if (typeof obj === 'object') {
        const c = this.currency(obj);
        if (c.symbol && preferSymbol) {
          return `${c.symbol}${parseFloat(num).toFixed(2)}`;
        } else {
          return `${parseFloat(num).toFixed(2)} ${c.code}`;
        }
      } else {
        return parseFloat(num).toFixed(2);
      }
    },

    currency(obj = {}){
      for (var el of (this.currencies || [])) {
        if (el.id === obj.currency_id) { return el; }
      }
      return {};
    },


    closeModal(move_to) {
      ({
        move_to
      } = this);

      if (typeof move_to === 'string') {
        if (document.querySelector(move_to)) { this.scrollTo(document.body, document.querySelector(move_to).getBoundingClientRect().top, 1); }
      }
      return this.modal = null;
    },


    image_url(image, type = "admin_tiny") { return image_url(image, type) },

    setSidebar(val) {
    /*
      Tailwind breakpoints:
      sm: min-width: 640px;
      md: min-width: 768px;
      lg: min-width: 1024px;
      xl: min-width: 1280px;
      2xl: min-width: 1536px;
    */


      if (window.innerWidth <= 768) {
        //console.log("<= 640px");
        this.app.sidebarDesktop = false;
        this.app.sidebarTablet = false;
        return this.app.sidebarMobile = val;
      } else if ((window.innerWidth > 769) && (window.innerWidth <= 1279)) {
        //console.log("> 640 && <= 1023");
        this.app.sidebarDesktop = false;
        this.app.sidebarMobile = false;
        return this.app.sidebarTablet = val;
      } else if ((window.innerWidth >= 1280)) {
        //console.log("=> 1024");
        this.app.sidebarMobile = false;
        this.app.sidebarTablet = false;
        return this.app.sidebarDesktop = val;
      }
    },

    escapeRegExp(str) {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },



    storeInitial(shop) {
      if (shop) { return shop.name.charAt(0); }
    },



    goToUpdateBilling(event) {
      if (event) { event.target.className += " disabled"; }
      return this.inJson(this.api_request("PATCH", "/subscriptions/payment")).then(hosted_page => {
        return window.location = hosted_page.url;
      });
    }
  }
}