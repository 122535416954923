<template lang="pug">
div
    template( v-if="loaded")
      language(@submit="onUpdate")
        template(#header)
          | {{ language.name }}
        template(#left)
          .button.delete.inverted.short( @click="onDelete" v-if="$can('translations__delete')" )
            | {{translate('admin.buttons.delete')}}
        template(#right)
          button.button.primary.short( type="submit" @click="onUpdate" v-if="$can('translations__update')" )
            | {{ translate('admin.translations.edit.update_language' ) }}
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '../../../mixins/root_computed.js';
import mixin_language from './language.js';
import language from '../language.vue';
export default{
  mixins: [Root,mixin_language],
  components: {language},
  data() {
    return {
      loaded: true,
      language: {},
      active: false,
      published: false,
      primary: false
    };
  },

  methods: {
    data_synced() { return this.loaded = true; },

    onCancel() { return this.closeModal(); },

    onDelete(e) {
      e.preventDefault();
      this.active = false;
      return this.performAction();
    },

    onUpdate(e) {
      e.preventDefault();
      return this.performAction();
    }
  }
}
</script>